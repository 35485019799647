<template>
<div>
    <GoogleAccountWarning v-if="!user.googleEmail && ['District Managed', 'Disabled'].includes(user.school.googleSetting)" />
    <form
        v-else
        class="kt-form kt-form--label-right"
    >
        <div
            class="alert alert-light alert alert-light alert-elevate"
            role="alert"
        >
            <div class="alert-icon">
                <i class="flaticon-warning kt-font-brand" />
            </div>
            <div class="alert-text">
                Google classroom permissions can be managed through
                <a
                    target="_blank"
                    href="https://myaccount.google.com/permissions"
                >
                    myaccounts.google.com/permissions
                </a>
            </div>
        </div>

        <div class="kt-section kt-section--first">
            <div class="form-group">
                <label class="">Assigned Email Account</label>
                <input
                    v-model="unlinkedGoogleEmail"
                    class="form-control"
                    type="text"
                    disabled="true"
                >
            </div>

            <div class="form-group">
                <label class="">Name</label>
                <input
                    v-model="googleFriendlyName"
                    class="form-control"
                    type="text"
                    disabled="true"
                >
            </div>

            <div class="form-group">
                <label class="">Google User</label>
                <input
                    :value="googleDomain"
                    class="form-control"
                    type="text"
                    disabled="true"
                >
            </div>
            <div class="form-group">
                <label class="">School Domain</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="la la-at" /></span>
                    </div>
                    <input
                        :value="googleDomain"
                        class="form-control"
                        type="text"
                        disabled="true"
                    >
                </div>
            </div>
        </div>

        <div class="kt-section">
            <div class="kt-section__body">
                <div class="kt-section kt-section--first">
                    <div class="kt-section__body">
                        <div class="form-group row">
                            <div class="col-12 ">
                                <a
                                    :href="$_userMixins_isSuperAdmin ? `javascript:alert('This cannot be performed while impersonating')` : redirectUri"
                                    class="btn btn-label-success btn-bold btn-sm kt-margin-t-5 kt-margin-b-5"
                                >
                                    Relink Google Account
                                </a>
                            </div>
                            <div
                                v-if="linkError"
                                class="col-12 text-danger"
                            >
                                We could not link you as your email doesn't match
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import GoogleAccountWarning from './GoogleAccountWarning.vue';
import userMixins from '../store/mixins/userMixins';

export default {
    name: 'GoogleClassroomConnect',
    components: { GoogleAccountWarning },
    mixins: [userMixins],
    data() {
        return {
            linkError: false,
            showDisconnectNotice: false,
            host: window.location.href.indexOf('localhost') > -1 ? 'http://localhost:3000' : '',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            googleFriendlyName: (state) => `${state.user.firstName} ${state.user.lastName}`,
            googleEmail: (state) => state.user.googleEmail,
            unlinkedGoogleEmail: (state) => state.user.unlinkedGoogleEmail,
            googleDomain(state) {
                return state.user.googleEmail ? state.user.googleEmail.split('@')[1] : '';
            },
            googleUser(state) {
                return state.user.googleEmail ? state.user.googleEmail.split('@')[0] : '';
            },
        }),
        isSetupWizard() {
            return this.$route.name == 'LinkGoogleAccount';
        },
        redirectUri() {
            const { pathname } = window.location;
            return `/auth/google/redirect?callbackUrl=${pathname}`;
        },
    },
    mounted() {
        const v = this;
        const urlParams = new URLSearchParams(window.location.search);
        const search = Object.fromEntries(urlParams);

        if (Object.keys(search).includes('error')) {
            const errorValue = search.error;
            if (errorValue === 'googleEmailMismatch') {
                v.linkError = true;
            }
        }
    },
    methods: {
        redirect() {
            window.location.href = '/auth/google/redirect';
        },
    },
};
</script>
