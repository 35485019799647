var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.user.googleEmail &&
      ["District Managed", "Disabled"].includes(_vm.user.school.googleSetting)
        ? _c("GoogleAccountWarning")
        : _c("form", { staticClass: "kt-form kt-form--label-right" }, [
            _c(
              "div",
              {
                staticClass:
                  "alert alert-light alert alert-light alert-elevate",
                attrs: { role: "alert" },
              },
              [
                _c("div", { staticClass: "alert-icon" }, [
                  _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
                ]),
                _c("div", { staticClass: "alert-text" }, [
                  _vm._v(
                    " Google classroom permissions can be managed through "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://myaccount.google.com/permissions",
                      },
                    },
                    [_vm._v(" myaccounts.google.com/permissions ")]
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "kt-section kt-section--first" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", {}, [_vm._v("Assigned Email Account")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.unlinkedGoogleEmail,
                      expression: "unlinkedGoogleEmail",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "true" },
                  domProps: { value: _vm.unlinkedGoogleEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.unlinkedGoogleEmail = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", {}, [_vm._v("Name")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.googleFriendlyName,
                      expression: "googleFriendlyName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "true" },
                  domProps: { value: _vm.googleFriendlyName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.googleFriendlyName = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", {}, [_vm._v("Google User")]),
                _c("input", {
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "true" },
                  domProps: { value: _vm.googleDomain },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", {}, [_vm._v("School Domain")]),
                _c("div", { staticClass: "input-group" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _c("i", { staticClass: "la la-at" }),
                    ]),
                  ]),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "true" },
                    domProps: { value: _vm.googleDomain },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "kt-section" }, [
              _c("div", { staticClass: "kt-section__body" }, [
                _c("div", { staticClass: "kt-section kt-section--first" }, [
                  _c("div", { staticClass: "kt-section__body" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-label-success btn-bold btn-sm kt-margin-t-5 kt-margin-b-5",
                            attrs: {
                              href: _vm.$_userMixins_isSuperAdmin
                                ? `javascript:alert('This cannot be performed while impersonating')`
                                : _vm.redirectUri,
                            },
                          },
                          [_vm._v(" Relink Google Account ")]
                        ),
                      ]),
                      _vm.linkError
                        ? _c("div", { staticClass: "col-12 text-danger" }, [
                            _vm._v(
                              " We could not link you as your email doesn't match "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }